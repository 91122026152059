import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Paper, Theme, Link, Box } from '@mui/material';
import { Table } from 'src/components/common/Table';
import { Promotion } from 'src/types/api';
import { format, differenceInDays, formatDuration } from 'date-fns';
import { routesMap } from 'src/routes';
import { ru } from 'date-fns/locale';

interface Props {
  promotions?: Promotion[] | null;
  totalCount?: number | null;
  refetch: () => any;
  isLoading: boolean;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'Promotions' },
);

export const Promotions: React.FunctionComponent<Props> = ({
  promotions,
  totalCount,
  refetch,
  isLoading,
}) => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>

      <Paper>
        <Table
          title="Акции"
          headers={[
            {
              title: 'id',
              component: 'th',
              scope: 'row',
              align: 'right',
              extract: (item: Promotion) =>
                item.id && (
                  <Link
                    href={routesMap.promotion.getRoute(item.id)}
                    target="_blank"
                  >
                    {item.id}
                  </Link>
                ),
            },
            {
              title: 'Период проведения',
              align: 'right',
              extract: (item: Promotion) => {
                const daysLeft = item.finish
                  ? differenceInDays(new Date(item.finish), new Date())
                  : 0;
                return (
                  item.start &&
                  item.finish && (
                    <Box>
                      <Box>
                        {format(new Date(item.start), 'yyyy-MM-dd')} -{' '}
                        {format(new Date(item.finish), 'yyyy-MM-dd')}
                      </Box>
                      <Box fontSize="11px">
                        {formatDuration(
                          {
                            days: differenceInDays(
                              new Date(item.finish),
                              new Date(item.start),
                            ),
                          },
                          { locale: ru },
                        )}
                        {Boolean(daysLeft > 0) ? (
                          <>
                            {', еще '}
                            {formatDuration(
                              {
                                days: daysLeft,
                              },
                              { locale: ru },
                            )}
                          </>
                        ) : (
                          ', завершена'
                        )}
                      </Box>
                    </Box>
                  )
                );
              },
            },
            {
              title: 'Оригинальное название',
              extract: (item: Promotion) => item.vendorName,
            },
            {
              title: 'Публичное название',
              extract: (item: Promotion) => item.title,
            },
            {
              title: 'Баннер',
              extract: (item: Promotion) =>
                item.banner && <img src={item.banner} height="48px" />,
            },
            {
              title: 'Воблер',
              extract: (item: Promotion) =>
                item.banner && <img src={item.wobbler} height="48px" />,
            },
          ]}
          data={promotions}
          totalCount={totalCount}
          onFetch={refetch}
          onChange={refetch}
          loading={isLoading}
        />
      </Paper>
    </div>
  );
};
